@import '../../styles/includes';

.ThreeColumnModule {
    @extend %container;

    &__Container {
        padding: 32px 0;
        border-bottom: 1px solid $colorRed50;
        @include media(m) {
            padding: 48px 0;
        }
    }
    &__Title {
        @extend %h3;
        font-family: $fontLight;
        font-size: 2.4rem;
        margin-bottom: calc($spacingM + $spacingS);

        @include media(m) {
            font-size: 3.2rem;
        }
    }

    &__Preamble {
        @extend %p;
        font-family: $fontLight;
        font-size: 1.8rem;
        margin-bottom: $spacingL;
        max-width: 800px;

        @include media(m) {
            font-size: 2rem;
        }
    }

    &__Grid {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: $spacingL;
        row-gap: calc($spacingM + $spacingS);
        align-items: baseline;

        @include media(m) {
            grid-template-columns: 1fr 1fr;
        }

        @include media(ml) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__Column {
        width: 100%;

        p {
            margin-bottom: 16px !important;
        }

        ul,
        ol {
            margin-bottom: $spacingL !important;
            font-family: $fontLight !important;
            color: #333333;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }

        ul {
            padding-left: 0px !important;
        }

        li {
            margin-bottom: 2px !important;
        }

        ul > li {
            list-style: none !important;
            padding-left: 16px !important;
            position: relative !important;

            &::before {
                background-color: #333333;
                border-radius: 50%;
                content: '';
                display: inline-block;
                height: 5px;
                left: 0;
                position: absolute;
                top: 9px;
                width: 5px;

                @include media(m) {
                    top: 9px;
                }
            }
        }
    }

    &__List {
        padding-left: $spacingM;
        list-style: disc;
    }

    &__ListItem {
        @extend %p;
        padding-bottom: $spacingS;
        font-family: $fontLight;
    }
}
